import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const uploadImageFunc = createAsyncThunk(
    "upload/image/post",
    async ({api , data , logOnUpload}) => {
        try {
            const response = await axios.post(api , data , {
                onUploadProgress : (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(progressEvent)
                    if(logOnUpload) {
                        logOnUpload(progress)
                    }
                }
            });
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
        
    }
)

const uploadImageSlicer = createSlice({
    name : "upload/image/post",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(uploadImageFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(uploadImageFunc.rejected , (state) => {
            state.loading = null
            state.data = null
            state.error = true
        })
        builder.addCase(uploadImageFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
})

export default uploadImageSlicer.reducer;