// اللوجو الي فوق علي الجنب 
// اللجو بيتغير لو القائمة الي علي الجنب اتغيرت وصغرت 
// اللون برضو علشان الدارك مود 


import React, { useContext, useEffect, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

import logo from '../../../media/images/academy-logo.png'
import { useDispatch, useSelector } from "react-redux";
import { getGeneralSettingsFunc } from "../../../store/custom-actions/platform-setting-actions/GetGeneralSettingsSlicel";
import ContextApi from "../../../store/ContextApi";
import { useTranslation } from "react-i18next";

const NavHader = () => {
  const {t} = useTranslation();
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  const contextApi = useContext(ContextApi)
  // // const general_settings_URL_APi = `${contextApi.dashboard_URL_API}/settings/general-settings`;
  // // const [settings , setSettings] = useState("")
  // // const [platformLogo , setPlatformLogo] = useState("")
  // const {loading:getSettingsLoading , data} = useSelector(
  //   state => state.getGeneralSettingsSlicer
  // );
  const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
    state => state.getBasicSettingsSlicer
  )
  const dispatch = useDispatch();
// const getGeneralSettingsData = async () => {
//     dispatch(getGeneralSettingsFunc(general_settings_URL_APi))
// }
// useEffect(() => {
//     getGeneralSettingsData()
// },[])
// useEffect(() => {
//   console.log(settingsData)
//     // settingsData && settingsData.generalSetting && setSettings(settingsData.generalSetting)
//     // settingsData && settingsData.generalSetting && setSettings(settingsData.generalSetting)
//     if(settingsData && settingsData.generalSetting) {
//         // const {generalSetting} = settingsData;
//         setPlatformLogo(settingsData.site_logo);
//         console.log(settingsData.site_logo)
//     }
// },[settingsData])
  return (
    <div className="nav-header flex " style={{zIndex : 99999}}>
      {
        getBasicSettingsLoading
        ?
        <p className="">{t("loading")}..</p>
        :
        <Link to="/" className="brand-logo">
          {background.value === "dark" || navigationHader !== "color_1" ? (
        <>
          <img src={settingsData?.site_logo ? `${contextApi.userPanelApi}/storage/${settingsData?.site_logo}` : logo} alt="logo"  className="logo-abbr" title="big"/>
        </>
          ) : (
        <>
          <img src={settingsData?.site_logo ? `${contextApi.userPanelApi}/storage/${settingsData?.site_logo}` : logo} alt="logo"  className="logo-abbr" title="smalll" style={{transition:'width 0.1s ease'}}  width={`${!toggle?"200":"100"}`} />
        </>
          )}
        </Link>
      }

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
