import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Nav, Spinner, Tab } from "react-bootstrap";
import ContextApi from "../../../../store/ContextApi";
import PageTitle from "../../../layouts/PageTitle";
import { getBasicSettingsFunc } from "../../../../store/custom-actions/basic-settings/GetBasicSettingsSlicer";
import { postBasicSettingsFunc } from "../../../../store/custom-actions/basic-settings/PostBasicSettingsSlicer";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";

function FooterSettingsForm() {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const basicSettingsApi = `${contextApi.dashboard_URL_API}/settings`
    const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
        state => state.getBasicSettingsSlicer
      )
    const {loading:postSettingsLoading} = useSelector(
        state => state.postBasicSettingsSlicer
    );
    const {loading:switchLangLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
    const dispatch = useDispatch();
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const [footerArContent , setFooterArContent] = useState("");
    const [footerEnContent , setFooterEnContent] = useState("");
    const [footerContent , setFooterContent] = useState("");
    const [activeTab , setActiveTab] = useState(languageData?.defaultLang?.slug)

    useEffect(() => {
        if(settingsData) {
            // console.log(settingsData)
            // languageData?.defaultLang?.slug?.toLowerCase() === "en" 
            // ? setFooterContent(settingsData?.footer_content?.en) 
            // : setFooterContent(settingsData?.footer_content?.ar)
            // activeTab?.toLowerCase() === "en" 
            // ? setFooterContent(settingsData?.footer_content?.en) 
            // : setFooterContent(settingsData?.footer_content?.ar)
            // console.log(settingsData?.footer_content)
            setFooterContent(settingsData?.footer_content?.[activeTab])
        }
    },[settingsData , activeTab])
    const handleSubmitSettings = async () => {
        const dataForm = new FormData();
        // activeTab === "en" ? dataForm.append("footer_content" , footerEnContent) : dataForm.append("footer_content" , footerArContent)
        dataForm.append("footer_content" , footerContent)
        dataForm.append("lang[footer_content]" , activeTab);
        dispatch(postBasicSettingsFunc({
            api : basicSettingsApi , 
            data : dataForm
        }))
        .then((result) => {
            if(result?.payload?.success) {
                dispatch(getBasicSettingsFunc(basicSettingsApi))
            }
        })
    }
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
const modules = {
  toolbar: {
        container: [
        [{ 'font': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': colors }, { 'background': colors }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'align': [] }],
        ['blockquote', 'code-block'],
        ['link', 'image', 'video'],
        ['clean']
        ],
    }
    };
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"Basic Settings"} customClasses={"mb-0"}/>
            <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button 
                        type="submit"
                        form="new-settings-form"
                        class={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        onClick={() => handleSubmitSettings()}
                        >
                            {
                                postSettingsLoading
                                ?
                                t("loading")
                                :
                                <>
                                    {t("Submit")}
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            {
                getBasicSettingsLoading
                ?
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                </div>
                :
                <div className="card p-3">
                    <div className="relative settings-form-content">
                        <div className="new-settings-form-div relative w-full ">
                            <div className="course-details-tab style-2">
                                <Tab.Container defaultActiveKey={activeTab} onSelect={(value) => setActiveTab(value)}>
                                    <nav>
                                    <Nav as='div' className="nav nav-tabs justify-content-start tab-auto" id="nav-tab" role="tablist">
                                        <Nav.Link as='button' className="nav-link" id="nav-about-tab" eventKey='ar'>{t("عربي")}</Nav.Link>
                                        <Nav.Link as='button' className="nav-link" id="nav-reviews-tab"  eventKey='en'>{t("English")}</Nav.Link>
                                    </Nav>
                                    </nav>
                                    <Tab.Content >
                                        <Tab.Pane eventKey={activeTab} className="about-course-content max-h-[500px] overflow-y-auto py-2">
                                            <ReactQuill
                                                modules = {modules}
                                                className="p-0 m-0 border-none outline-none form-control bg-white " 
                                                value={footerContent} 
                                                theme="snow" 
                                                onChange={(content) => setFooterContent(content)} />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default FooterSettingsForm;